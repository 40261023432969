var canopyThemeCookieName = 'cs-colour-theme';
function getCookie(name) {
  let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function getTheme() {
  let themeCookie = getCookie(canopyThemeCookieName);
  return initialTheme = (themeCookie === 'light' || themeCookie === 'dark') ? themeCookie :
  window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
}

document.documentElement.setAttribute('data-bs-theme', getTheme());
